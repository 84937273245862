var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Row', [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('Row', {
    staticStyle: {
      "padding": "12px",
      "display": "block"
    }
  }, [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('h4', [_vm._v("Find vendors in the Agnostik Library")]), _c('br')]), !_vm.isAdding ? _c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('Input', {
    staticStyle: {
      "width": "100%",
      "display": "block"
    },
    attrs: {
      "type": "text",
      "suffix": "ios-search",
      "placeholder": "Vendor name"
    },
    on: {
      "input": _vm.debounceInput
    },
    model: {
      value: _vm.inputName,
      callback: function callback($$v) {
        _vm.inputName = $$v;
      },
      expression: "inputName"
    }
  }), _vm.count ? _c('ul', [_c('li', {
    staticStyle: {
      "padding": "8px 3px",
      "display": "block",
      "text-align": "right"
    }
  }, [_vm._v(" " + _vm._s(_vm.vendors.length) + "/" + _vm._s(_vm.count) + " vendors displayed ")])]) : _vm._e()], 1) : _vm._e(), _vm.noVendorFound ? _c('Col', [_c('ul', [_c('li', {
    staticStyle: {
      "padding": "8px 3px",
      "display": "block",
      "text-align": "right"
    }
  }, [_vm._v(" No vendors founds ")])]), _c('br'), _c('span', [_vm._v("If you want to add a new vendor that is not in the Agnostik library, "), _c('br'), _vm._v("please contact our customer support:")]), _c('br'), _c('br'), _c('a', {
    staticStyle: {
      "display": "block",
      "text-align": "center"
    },
    attrs: {
      "HREF": "mailto:support@agnostik.io"
    }
  }, [_vm._v("support@agnostik.io")])]) : _vm._e(), _c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('ul', {
    staticClass: "list-vendor",
    staticStyle: {
      "max-height": "450px: overflow:auto"
    }
  }, _vm._l(_vm.vendors, function (vendor, key) {
    return _c('li', {
      key: key,
      staticClass: "list-item-vendor",
      style: {
        padding: '8px 3px'
      }
    }, [_c('Row', {
      attrs: {
        "type": "flex",
        "justify": "space-between"
      }
    }, [_c('span', [_vm._v(_vm._s(_vm.ellipsis(vendor.name)))]), _c('span', [!_vm.vendorList.find(function (vdn) {
      return vdn.uuid === vendor.uuid;
    }) ? _c('Icon', {
      style: {
        fontSize: '24px',
        cursor: 'pointer',
        margin: '0 5px'
      },
      attrs: {
        "type": "md-add"
      },
      on: {
        "click": function click($event) {
          return _vm.addVendor(vendor);
        }
      }
    }) : _c('span', [_vm._v("Vendor present in VL or CMP")])], 1)])], 1);
  }), 0)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }