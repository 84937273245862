<template>
  <Row>
    <!-- <Col span="24"
         v-show="viewedVendor"
         style="min-height:850px"
    >
      <VendorCardContent :vendor-props="viewedVendor"
                         :is-open="!!viewedVendor"
                         :is-checking-purposes="true"
                         :is-viewer-mode="true"
                         @close-view="closeViewer"
      />
    </Col> -->
    <Col span="24">
      <Row style="padding:12px; display:block">
        <Col span="24">
          <h4>Find vendors in the Agnostik Library</h4>
          <br>
        </Col>
    
        <Col span="24"
             v-if="!isAdding"
        >
          <Input type="text"
                 style="width:100%;display:block;"
                 suffix="ios-search"
                 placeholder="Vendor name"
                 v-model="inputName"
                 @input="debounceInput"
          />
          
          <ul v-if="count">
            <li style="padding:8px 3px;display:block;text-align:right">
              {{ vendors.length }}/{{ count }} vendors displayed
            </li>
          </ul>
        </Col>
        <Col v-if="noVendorFound">
          <ul>
            <li style="padding:8px 3px;display:block;text-align:right">
              No vendors founds
            </li>
          </ul>
          <br>
          <span>If you want to add a new vendor that is not in the Agnostik library, <br>please contact our customer support:</span>
          <br>
          <br>
          <a HREF="mailto:support@agnostik.io"
             style="display:block;text-align:center"
          >support@agnostik.io</a>
        </Col>
        <Col span="24">
          <ul style="max-height:450px: overflow:auto"
              class="list-vendor"
          >
            <li v-for="(vendor, key) of vendors"
                :style="{padding: '8px 3px'}"
                class="list-item-vendor"
                :key="key"
            >
              <Row type="flex"
                   justify="space-between"
              >
                <span>{{ ellipsis(vendor.name) }}</span>
                <span>
                  <!-- <Icon type="ios-eye"
                        :style="{fontSize:'24px', cursor:'pointer', margin:'0 5px'}"
                        @click="viewVendor(vendor)"
                  /> -->
                  <Icon type="md-add"
                        v-if="!vendorList.find(vdn => vdn.uuid === vendor.uuid)"
                        :style="{fontSize:'24px', cursor:'pointer', margin:'0 5px'}"
                        @click="addVendor(vendor)"
                  />
                  
                  <span v-else>Vendor present in VL or CMP</span>
                </span>
              </Row>
            </li>
          </ul>
        </Col>
        <!-- <Col span="24"
             style="height:fit-content"
        >
          <Button type="primary"
                  style="width:100%"
          >
            Cancel
          </Button>
        </Col>
        <Col span="24"
             style="height:fit-content"
        >
          <Button type="primary"
                  :disabled="true"
                  style="width:100%"
          >
            Confirm
          </Button>
        </Col> -->
      </Row>
    </Col>
  </Row>
</template>
<script>
import * as _ from 'lodash'
import {mapActions, mapGetters} from 'vuex'
import { eventBus } from '@/main'

export default {
  name: 'DeclareNewVendorPoptip',
  data: function () {
    return {
      inputName: '',
      bus: eventBus,
      vendors: [],
      count: 0,
      viewedVendor: null,
      isAdding: false,
      noVendorFound: false
    }
  },
  mounted () {
    
  },
  computed: {
    ...mapGetters({
      draftVendorList: 'vendor/draftVendorList',
      vendorList: 'vendor/vendorList'
    })
  },
  methods: {
    closeViewer () {
      this.viewedVendor = null
    },
    viewVendor (vendor) {
      this.viewedVendor = null
      this.viewedVendor = vendor
    },
    addVendor (vendor) {
      this.viewedVendor = null
      this.viewedVendor = vendor
      this.bus.$emit('add-vendor-in-list', [vendor])
      this.$parent.handleClose()
      // this.isAdding = true
      // this.declaredVendorsBag.push(vendor)
      // this.vendors = this.vendors.filter(vendor => !this.declaredVendorsBag.find(elm => elm.uuid === vendor.uuid))
    },
    ellipsis (string = '') {
      if (string && string.length > 35) {
        return string.substring(0, 30) + '...'
      }
      return string
    },
    debounceInput: _.debounce(function (e) {
      this.viewedVendor = null
      const params = {
        category: true,
        country: true,
        count: 1,
        limit: 10,
        q: this.inputName.trim()
      }
      return this.getVendors(params)
        .then(r => {
          if (r.success) {
            this.vendors = r.data
            this.count = r.pagination.count
            if (r.pagination.count) this.noVendorFound = false
            if (!r.pagination.count) this.noVendorFound = true
          }
        })
    }, 500),
    ...mapActions({
      getVendors: 'vendor/getVendors'
    })
  }
}
</script>
<style lang="scss">
.list-vendor {
  .list-item-vendor {
  //border-bottom:1px solid #ebebeb
  }
  &:last-child {
    border:none!important;
  }
  &:nth-child(even) {
    background: #ebebeb;
  }
}


</style>