var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isColumnFilterOpen ? _c('Card', {
    style: {
      width: '250px',
      position: 'absolute',
      'z-index': '3',
      left: '549px',
      top: _vm.$route.path === '/trackers' || _vm.$route.path === '/vendors' ? '150px' : '130px',
      'box-shadow': '0 1px 6px rgb(0 0 0 / 20%)'
    },
    attrs: {
      "bordered": true
    }
  }, [_c('span', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("Columns filter")]), _c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "slot": "extra"
    },
    on: {
      "click": function click($event) {
        _vm.$store.state.options.isColumnFilterOpen = false;
      }
    },
    slot: "extra"
  }, [_c('i', {
    staticClass: "fas fa-times"
  })]), _c('List', _vm._l(_vm.columns, function (col, k) {
    return _c('ListItem', {
      key: k
    }, [_c('Checkbox', {
      attrs: {
        "label": col.title
      },
      on: {
        "on-change": function onChange(check) {
          return _vm.triggerCheck(check, col.title);
        }
      },
      model: {
        value: col.checked,
        callback: function callback($$v) {
          _vm.$set(col, "checked", $$v);
        },
        expression: "col.checked"
      }
    }, [_c('span', {
      staticStyle: {
        "margin-left": "10px"
      }
    }, [_vm._v(" " + _vm._s(col.title) + " ")])])], 1);
  }), 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }