import moment from 'moment'
import {isObjectPath, resolveObjectPath} from '@/helpers/objects'

export default {
  data () {
    return {
      /**
       * @note You can add render shortcuts function here. For instance for the date render instead of
       * declaring the render function itself (as last parameter). You'll just have to pass a string 'date',
       * and it will automatically use the function date in defaultRenderMethods.
       */
      defaultRenderMethods: {
        date: (row, key) => ({content: this.humanReadableDate(resolveObjectPath(row, key))}),
        vendorLink: (row, _) => ({
          tag: 'router-link',
          props: {to: `/vendors/${row.uuid}`},
          content: row => ({tag: 'strong', content: row.name})
        }),
        underQualification: (row, key) => ({content: resolveObjectPath(row, key) || 'Under qualification'}),
        enable: (row, key) => resolveObjectPath(row, key) && resolveObjectPath(row, key) !== 'disabled' ? ({
          tag: 'span',
          classes: 'chips chips--success',
          content: this.$t('global.property.enable')
        }) : ({
          tag: 'span',
          classes: 'chips chips--red',
          content: this.$t('global.property.disable')
        })
      }
    }
  },
  methods: {
    /**
     * Create a column object for IView Table component.
     * @param title -> Column name that will be displayed to the screen into the table header.
     * @param key -> Column field name in the row object.
     * @param sortKey -> Key used by back-end to sort the item list by a given column.
     * @param render -> Optional parameter. Function used to set a custom cell display.
     *                  @param row -> Current row object.
     *                  @returns {Object} -> tag -> HTML tag or component used to display cell.
     *                                       props -> Props passed to the tag.
     *                                       classes -> CSS class applied on tag.
     *                                       events -> events attributes.
     *                                       content -> Content inner the tag.
     * @return {Object} -> Column object used by IView Table component.
     */
    createColumn (title, key, sortKey, render, width) {
      let column = {
        title,
        key,
        sortKey,
        sortable: 'custom',
        width
      }

      if (!sortKey) {
        delete column.sortKey
        delete column.sortable
      }
      if (isObjectPath(key)) {
        column.render = (h, params) => h('span', null, [resolveObjectPath(params.row, key)])
      }
      if (render) {
        if (render.type === "custom") {
          column.render = render.customMethod()
        } else {
          column.render = (h, params) => {
            let renderObject = null
  
            if (typeof render === 'string' || render instanceof String) {
              renderObject = this.defaultRenderMethods[render](params.row, key)
            } else if (render instanceof Function) {
              renderObject = render(params.row)
            }
  
            let {tag, props, classes, events, href, style, content} = renderObject
  
  
            tag = tag || 'span'
            if (content instanceof Function) {
              const innerRender = content(params.row)
              const innerContent = h(innerRender.tag, {
                props: innerRender.props,
                classes: innerRender.classes,
                on: innerRender.events,
                href: innerRender.href,
                style: innerRender.style
              }, innerRender.content)
  
              innerRender.tag = innerRender.tag || 'span'
              return h(tag, {props: props, class: classes, on: events, href: href, style: style}, [innerContent])
            }
            return h(tag, {props: props, class: classes, on: events, href: href, style: style}, [content])
          }
        }
        
      }
      return column
    },
    /**
     * Take a date as parameter and returns a readable string of this date.
     * @param date -> Date, either string or Date instance.
     * @returns {string|null} -> String in format "month day year, hour:minutes:seconds"
     */
    humanReadableDate (date) {
      if (date) {
        return date && moment(date).format('YYYY-MM-DD')
      }
      return null
    }
  }
}
