<template>
  <Row :gutter="24"
       style="margin: 20px 0; align-items:center"
       class="vendor-list-filters"
  >
    <Col>
      <Input type="text"
             style="width:300px;"
             suffix="ios-search"
             placeholder="Vendor name"
             v-model="filters.inputName"
             @input="debounceInput"
      />
    </Col>
    <Col style="margin-left:auto">
      <Poptip placement="left"
              ref="search-vendor-poptip"
              :width="520"
      >
        <Button type="primary"
                icon="md-add"
        >
          Declare a new vendor
        </Button>
        <DeclareNewVendorPoptip slot="content"
                                ref="declareNewVendorRef"
        />
      </Poptip>
    </Col>
    <Col span="24"
         style="margin-top:12px"
    >
      <div style="display:flex; align-items:center">
        <Poptip 
          @on-popper-show="triggerPop('legalBasis', true)"
          @on-popper-hide="triggerPop('legalBasis', false)"
          placement="bottom"
        >
          <span class="filters-btn"
                :class="{ 'filter-active': filtersPoptipStatus.legalBasis }"
          >Legal basis
            <span v-if="filters.legalBasis.length">{{ filters.legalBasis.length }}</span>
            <Icon type="ios-arrow-down"
                  style="margin-left:8px;"
            />
          </span>
          <p slot="content">
            <CheckboxGroup v-model="filters.legalBasis">
              <br>
              <Checkbox style="margin:5px 0"
                        label="Only Consent"
              />
              <br>
              <Checkbox style="margin:5px 0"
                        label="Only Legitimate Interest"
              />
            </CheckboxGroup>
            <br>
          </p>
        </Poptip>
        <Poptip 
          @on-popper-show="triggerPop('tcf_f_p', true)"
          @on-popper-hide="triggerPop('tcf_f_p', false)"
          placement="bottom"
        >
          <span class="filters-btn"
                :class="{ 'filter-active': filtersPoptipStatus.tcf_f_p }"
          >Purposes and features
            <span v-if="filters.tcf_f_p.length">{{ filters.tcf_f_p.length }}</span>
            <Icon type="ios-arrow-down"
                  style="margin-left:8px;"
            />
          </span>
          <p slot="content">
            <br>
            <CheckboxGroup v-model="filters.tcf_f_p">
              <Checkbox style="margin:5px 0"
                        label="Only purpose 1 declared on consent"
              /><br>
              <Checkbox style="margin:5px 0"
                        label="Purpose 1 NOT declared"
              />
            </CheckboxGroup>
            <br>
            <!-- <Checkbox style="margin-bottom:15px"
                      label="Only declared in VL"
                      v-model="filters.vl"
            >
              Detected vendors in CMP
            </Checkbox>
          </p> -->
          </p>
        </Poptip>
        <Poptip
          v-if="this.userHasRole('super-admin')"
          @on-popper-show="triggerPop('knowVendors', true)"
          @on-popper-hide="triggerPop('knowVendors', false)"
          placement="bottom"
        >
          <span class="filters-btn"
                :class="{ 'filter-active': filtersPoptipStatus.knowVendors }"
          >Known vendors
            <span v-if="filters.knowVendors.length">{{ filters.knowVendors.length }}</span>
            <Icon type="ios-arrow-down"
                  style="margin-left:8px;"
            />
          </span>
          <p slot="content">
            <br>
            <CheckboxGroup v-model="filters.knowVendors">
              <Checkbox style="margin-bottom:15px"
                        label="Only known vendors"
              >
                Only unknown vendors
              </Checkbox>
            </CheckboxGroup>
            <!-- <Checkbox style="margin-bottom:15px"
                      label="Only declared in VL"
                      v-model="filters.vl"
            >
              Detected vendors in CMP
            </Checkbox>
          </p> -->
          </p>
        </Poptip>
        <Poptip 
          @on-popper-show="triggerPop('vl', true)"
          @on-popper-hide="triggerPop('vl', false)"
          placement="bottom"
        >
          <span class="filters-btn"
                :class="{ 'filter-active': filtersPoptipStatus.vl }"
          >In vendor list
            <span v-if="filters.vl">1</span>
            <Icon type="ios-arrow-down"
                  style="margin-left:8px;"
            />
          </span>
          <p slot="content">
            <br>
            <Checkbox style="margin-bottom:15px"
                      label="Only declared in VL"
                      v-model="filters.vl"
            >
              Newly declared vendors in vendorlist
            </Checkbox>
            <br>
            <!-- <Checkbox style="margin-bottom:15px"
                      label="Only declared in VL"
                      v-model="filters.vl"
            >
              Detected vendors in CMP
            </Checkbox>
          </p> -->
          </p>
        </Poptip>
        <Poptip 
          @on-popper-show="triggerPop('tcf', true)"
          @on-popper-hide="triggerPop('tcf', false)"
          placement="bottom"
        >
          <span class="filters-btn"
                :class="{ 'filter-active': filtersPoptipStatus.tcf }"
          >TCF
            <span v-if="filters.tcf.length">{{ filters.tcf.length }}</span>
            <Icon type="ios-arrow-down"
                  style="margin-left:8px;"
            />
          </span>
          <p slot="content">
            <CheckboxGroup v-model="filters.tcf">
              <Checkbox style="margin:5px 0"
                        label="In TCF"
              />
              <br>
              <Checkbox style="margin:5px 0"
                        label="Not in TCF"
              />
            </CheckboxGroup>
          </p>
        </Poptip>
        <Poptip 
          @on-popper-show="triggerPop('index', true)"
          @on-popper-hide="triggerPop('index', false)"
          placement="bottom"
        >
          <span class="filters-btn"
                :class="{ 'filter-active': filtersPoptipStatus.index }"
          >Trust index
            <span v-if="filters.index.length">{{ filters.index.length }}</span>
            <Icon type="ios-arrow-down"
                  style="margin-left:8px;"
            />
          </span>
          <p slot="content">
            <CheckboxGroup v-model="filters.index">
              <Checkbox style="margin:5px 0"
                        label="A"
              >
                <Tag style="margin-left:5px"
                     color="#81C327"
                     type="dot"
                >
                  A
                </Tag>
              </Checkbox>
              <br>
              <Checkbox style="margin:5px 0"
                        label="B"
              >
                <Tag style="margin-left:5px"
                     color="#6BAAD8"
                     type="dot"
                >
                  B
                </Tag>
              </Checkbox>
              <br>
              <Checkbox style="margin:5px 0"
                        label="C"
              >
                <Tag style="margin-left:5px"
                     color="#FFBD41"
                     type="dot"
                >
                  C
                </Tag>
              </Checkbox>
              <br>
              <Checkbox style="margin:5px 0"
                        label="D"
              >
                <Tag style="margin-left:5px"
                     color="#FF7850"
                     type="dot"
                >
                  D
                </Tag>
              </Checkbox>
              <br>
              <Checkbox style="margin:5px 0"
                        label="E"
              >
                <Tag style="margin-left:5px"
                     color="#D55D78"
                     type="dot"
                >
                  E
                </Tag>
              </Checkbox>
            </CheckboxGroup>
          </p>
        </Poptip>
        <Poptip 
          @on-popper-show="triggerPop('categories', true)"
          @on-popper-hide="triggerPop('categories', false)"
          placement="bottom"
        >
          <span class="filters-btn"
                :class="{ 'filter-active': filtersPoptipStatus.categories }"
          >Categories
            <span v-if="filters.categories.length">{{ filters.categories.length }}</span>
            <Icon type="ios-arrow-down"
                  style="margin-left:8px;"
            />
          </span>
          <p slot="content"
             style="max-height:250px;overflow:auto"
          >
            <CheckboxGroup v-model="filters.categories">
              <template v-for="(cat, key) of categories">
                <Checkbox style="margin:5px 0"
                          :key="key"
                          :label="cat.name"
                />
                <br :key="key + 'br'">
              </template>
            </CheckboxGroup>
          </p>
        </Poptip>
        <Poptip 
          @on-popper-show="triggerPop('countryLaws', true)"
          @on-popper-hide="triggerPop('countryLaws', false)"
          placement="bottom"
        >
          <span class="filters-btn"
                :class="{ 'filter-active': filtersPoptipStatus.countryLaws }"
          >Country Adequation
            <span v-if="filters.countryLaws.length">{{ filters.countryLaws.length }}</span>
            <Icon type="ios-arrow-down"
                  style="margin-left:8px;"
            />
          </span>
          <p slot="content">
            <CheckboxGroup v-model="filters.countryLaws">
              <Checkbox style="margin:5px 0"
                        label="EU or EEA member country or adequate country"
              />
              <br>
              <Checkbox style="margin:5px 0"
                        label="Partially adequate country"
              />
              <br>
              <Checkbox style="margin:5px 0"
                        label="Non adequate country"
              />
              <br>
              <Checkbox style="margin:5px 0"
                        label="No data protection law(s)"
              />
            </CheckboxGroup>
          </p>
        </Poptip>
        <span style="cursor:pointer;"
              @click="resetFilters"
        ><strong>Clear all</strong></span>
          
        
        <!-- <Row style="width:100%; display:flex;justify-content: center;margin:10px 0">
          <Button type="primary"
                  @click="validFilters"
          >
            Apply
          </Button>
        </Row> -->
      </div>
      <!-- </Poptip> -->
    </Col>
  </Row>
</template>
<script>
import { eventBus } from '@/main'
import { mapActions, mapGetters } from 'vuex'
import DeclareNewVendorPoptip from './DeclareNewVendorPoptip.vue'
import * as _ from 'lodash'
export default {
  name: 'VendorListFilters',
  components: {
    DeclareNewVendorPoptip
  },
  data: function () {
    return {
      visible: false,
      bus: eventBus,
      viewedVendor: false,
      basePagination: {
        count: 0,
        limit: 10,
        sort: '',
        page: 1,
        current_page: 1,
        direction: ''
      },
      filtersPoptipStatus: {
        legalBasis: false,
        tcf_f_p: false,
        tcf: false,
        index: false,
        categories: false,
        countryLaws: false,
        knowVendors: false,
        vl: false,
      },
      filters: {
        inputName: '',
        legalBasis: [],
        tcf_f_p: [],
        tcf: [],
        index: [],
        categories: [],
        countryLaws: [],
        knowVendors: [],
        vl: false

      },
      categories: null
    }
  },
  watch: {
    filters: {
      deep:true,
      handler: function () {
        this.bus.$emit('paginate-vendor-list', this.basePagination)
        this.bus.$emit('trigger-filter', this.filters)
      }
    }
  },
  mounted () {
    return this.vendorGetVendorcategories({})
      .then(r => {
        this.categories = r.data
      })
  },
  computed: {
    ...mapGetters({
      userHasRole: 'user/hasRole',

    })
  },
  methods: {
    ...mapActions({
      vendorGetVendorcategories: 'vendor/getVendorcategories',
    }),
    triggerPop (filter, val) {
      this.filtersPoptipStatus[filter] = val
    },
    resetFilters () {
      this.filtersPoptipStatus = {
        legalBasis: false,
        tcf: false,
        index: false,
        categories: false,
        countryLaws: false,
        vl: false,
        knowVendors: false
      }
      this.filters = {
        inputName: '',
        legalBasis: [],
        tcf: [],
        tcf_f_p: [],
        index: [],
        categories: [],
        countryLaws: [],
        knowVendors: []

      }
      this.bus.$emit('paginate-vendor-list', this.basePagination)
      this.bus.$emit('trigger-filter', false)
    },
    validFilters () {
      this.bus.$emit('paginate-vendor-list', this.basePagination)
      this.bus.$emit('trigger-filter', this.filters)
    },
    debounceInput: _.debounce(function (e) {
      this.filters.inputName = e.trim() || ''
      this.bus.$emit('paginate-vendor-list', this.basePagination)
      this.bus.$emit('trigger-filter', this.filters)
    }, 500),
  }
}
</script>
<style lang="scss">
.vendor-list-filters {
  .ivu-poptip-body-content {
  overflow: hidden!important
}
.filter-active {
  background: #1CA08D!important;
  color: #fff!important;
  border: 1px solid #1CA08D!important;
  span {
    display: block;
    margin-left:8px;
    width: 19px!important;
    border-radius: 50%;
    line-height: 19px;
    color: inherit;
    background: #fff;
    text-align: center;
  }
  &:hover {
    background: #ebebeb
    span {
      color: #fff;
      background: #1CA08D;
    }
  }
}
.filters-btn {
  box-sizing: border-box;
  padding: 7px;
  cursor:pointer;
  margin-right:12px;
  border: 1px solid #ebebeb;
  display:flex;
  align-items: center;
  span {
    margin-left:10px;
    display: block;
    color: #fff;
    background: #1CA08D;
    text-align: center;
    width: 19px;
    line-height: 19px;
    border-radius: 50%;
    text-align: center;
    padding: 0;
  }
  &:hover {
    background: #ebebeb
    span {
      border: 1px solid #fff;
      color: inherit;
      background: inherit;
    }
  }
}
}

</style>