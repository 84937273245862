/**
 * isObjectPath returns true if string is an object path to a property.
 * @param path {String}
 * @returns {Boolean}
 */
const isObjectPath = path => /[a-z_](\w*\.[a-z_]\w*)+/i.test(path)

/*
function resolveObjectPath (object, path) {
  const keyBeforeBracketRgx = /(\w+)(\[.+\])+/i
  const bracketRgx = /\[(.+)\]/i

  const resolveObjectId = (object, id) => {
    if (id >= 0 && id < object.length) {
      return object[id]
    }
    return null
  }

  const resolveObjectKey = (object, key) => {
    if (!(object instanceof Object)) {
      return object
    }
    if (!object.hasOwnProperty(key) || object[key] === undefined || object[key] === null) {
      return null
    }
    return object[key]
  }

  const resolveObjectBrackets = (object, bracketString) => {
    const bracketStrKeyRgx = /('|")(\w+)('|")/i
    const bracketKey = bracketString.match(bracketRgx)[1]

    if (bracketStrKeyRgx.test(bracketKey)) {
      return resolveObjectKey(object, bracketKey.match(bracketStrKeyRgx)[2])
    }
    if (!isNaN(parseInt(bracketKey))) {
      return resolveObjectId(object, parseInt(bracketKey))
    }
    return null
  }

  const resolveAllBrackets = (object, bracketPairsStr) => {
    while (bracketRgx.test(bracketPairsStr)) {
      const bracketPairStr = bracketPairsStr.match(/^\[(('|")(\w+)('|")|(-*[0-9]+))\]/)[0]

      if ((object = resolveObjectBrackets(object, bracketPairStr)) === null) {
        return null
      }
      bracketPairsStr = bracketPairsStr.replace(bracketPairStr, '')
    }
    return object
  }

  const resolveObjectProperty = (object, property) => {
    const keyMatch = property.match(keyBeforeBracketRgx)

    if (!bracketRgx.test(property)) {
      return resolveObjectKey(object, property)
    }

    const key = keyMatch[1]

    if ((object = resolveObjectKey(object, key)) === null) {
      return null
    }
    return resolveAllBrackets(object, property.replace(key, ''))
  }

  if (!object) {
    return null
  }
  if (!path.includes('.')) {
    if (!keyBeforeBracketRgx.test(path) && bracketRgx.test(path)) {
      return resolveAllBrackets(object, path)
    }
    return resolveObjectProperty(object, path)
  }
  for (const property of path.split('.')) {
    object = resolveObjectProperty(object, property)
  }
  return object
}
 */

/**
 * resolveObjectPath takes an object as parameters and a path to a given property.
 * A path is a string that defines how to access to a given property inside an object.
 * (eg: object = {a: b: {c: 'Hello'}}, path = 'a.b.c' -> result: 'Hello')
 * It supports both "." and "[]" syntax to retrieve value of an object property.
 * Obviously "[]" can be used to retrieve value of object property and element at given index in array as well.
 *
 * Here is an example of use:
 * const object = {
 *   body: {
 *     nodes: [
 *       {
 *         title: 'A node',
 *         coordinates: {x: 0, y: 5}
 *       },
 *       {
 *         title: 'Another',
 *         coordinates: {x: 2, y: 5}
 *       }
 *     ]
 *   }
 * }
 *
 * For instance, you can retrieve the x coordinates of the first node with all these strings as path parameter:
 *
 * "body.nodes[0].coordinates.x"
 * "body["nodes"][0].coordinates.x"
 * "body["nodes"][0]["coordinates"].x"
 * "body["nodes"][0]["coordinates"]["x"]"
 * ...
 *
 * @param object {Object} -> Object on which we want to find property.
 * @param path {String} -> Path of the property in the object.
 * @returns {null|*}
 */
function resolveObjectPath (object, path) {
  const regexes = {
    IS_BRACKET_PAIR: /\[(.+)\]/i,
    /*
     * "['key']", "[0]" -> true
     * "[azaz" -> false
     */
    IS_OBJ_KEY_THEN_BRACKET_PAIRS: /(\w+)(\[.+\])+/i,
    /*
     * "hello[someting]" -> true
     * "[something]" -> false
     */
    IS_OBJ_KEY_BRACKETS: /('|")(\w+)('|")/i,
    /*
     * "['someting']" -> true
     * "[0]" -> false
     */
    IS_ARR_IDX_BRACKETS: /\[[0-9]+\]/,
    /*
     * "[0]" -> true
     * "['someting']" -> false
     */
    MATCH_FIRST_BRACKET_PAIR: /^\[(('|")(\w+)('|")|(-*[0-9]+))\]/
    /*
     * "['key']['hello']" -> ['key']
     * "coucou['someting']" -> null
     */
  }

  /**
   * Take an object and the name of property. If object has this property, it returns its value. If object is not an object,
   * object is returned and if object has not this property it returns null.
   *
   * Eg: readProperty({name: 'Mike', age: 22}, 'name') -> 'Mike'.
   *
   * @param object {Object|*} -> The object where to fetch the property.
   * @param property {String} -> The given property.
   * @returns {*|null}
   */
  const readProperty = (object, property) => {
    if (!(object instanceof Object)) {
      return object
    }
    if (!object.hasOwnProperty(property) || object[property] === undefined || object[property] === null) {
      return null
    }
    return object[property]
  }

  /**
   * This function takes as parameter an object and a string that represents series of bracket pairs in
   * a JavaScript format used to fetch a property in object with brackets.
   * Eg: "object.myProperty[key1][key2]...".
   *                       ^-------------^
   *                      It reads all this part
   *
   * It loops over each bracket pair and do as follows:
   *    - If a bracket pair is a property it set object to the value of this property.
   *    - If a bracket pair is an index it consider that object is an array and set object to the value of the element at given index.
   *
   * @param object {Object|*} -> The object where to fetch a given property.
   * @param bracketsProperties {String} -> Series of bracket properties or indexes in a JavaScript format used to access a property.
   * @returns {null|*}
   */
  const readAllBracketsProperties = (object, bracketsProperties) => {
    /**
     * Read a single bracket property
     *
     * @returns {*|null}
     */
    const readBracketProperty = bracketStr => {
      const property = bracketStr.match(regexes.IS_BRACKET_PAIR)[1]
      let id = 0

      if (regexes.IS_OBJ_KEY_BRACKETS.test(property)) {
        return readProperty(object, property.match(regexes.IS_OBJ_KEY_BRACKETS)[2])
      }
      if (!isNaN(id = parseInt(property))) {
        if (id >= 0 && id < object.length) {
          return object[id]
        }
      }
      return null
    }

    while (regexes.IS_BRACKET_PAIR.test(bracketsProperties)) {
      /**
       * We fetch the first bracket property.
       *
       * Eg: "['propertyOne']['propertyTwo']"
       *      ^-------------^
       *       This property.
       */
      const firstBracketProperty = bracketsProperties.match(regexes.MATCH_FIRST_BRACKET_PAIR)[0]

      if ((object = readBracketProperty(firstBracketProperty)) === null) {
        return null
      }
      bracketsProperties = bracketsProperties.replace(firstBracketProperty, '')
    }
    return object
  }

  /**
   * readToken do the same a resolveObjectPath but with one token.
   * A token is an element of the array resulting from the split of the path with the "." character.
   * There are 2 cases of token to handle:
   *    1. "property"
   *    2. "property['otherProperty']['maybeAThirdOne'][andFinallyAnIndex]..."
   *
   * Having just "['propertyOne']['propertyTwo'] is forbidden, in this case we returns null.
   * @param object
   * @param token
   * @returns {null|*}
   */
  const readToken = (object, token) => {
    const propertyNameBeforeBracketsMatch = token.match(regexes.IS_OBJ_KEY_THEN_BRACKET_PAIRS)

    /**
     * If there is no brackets in the token (case 1.), it's just a property name, so we return its value.
     * Eg: "object.aKey"
     */
    if (!regexes.IS_BRACKET_PAIR.test(token)) {
      return readProperty(object, token)
    }

    if (!propertyNameBeforeBracketsMatch || propertyNameBeforeBracketsMatch.length < 1) {
      return null
    }

    const propertyNameBeforeBrackets = propertyNameBeforeBracketsMatch[1]

    /**
     * If there is brackets in the token (case 2.) we read the property before the brackets.
     * And then call readAllBrackets that reads all the brackets property.
     *
     * Eg:
     * 1. "anyKeyInObj["anotherKey"][andAnIndex]"
     *        ^
     *        |
     *  We read this key.
     *
     * 2. "anyKeyInObj["anotherKey"][andAnIndex]"
     *                ^------------------------^
     *                  We read all these ones.
     *
     */
    if ((object = readProperty(object, propertyNameBeforeBrackets)) === null) {
      return null
    }
    return readAllBracketsProperties(object, token.replace(propertyNameBeforeBrackets, ''))
  }

  if (!object) {
    return null
  }
  if (!path.includes('.')) {
    return readToken(object, path)
  }
  for (const property of path.split('.')) {
    object = readToken(object, property)
  }
  return object
}

module.exports = {
  isObjectPath,
  resolveObjectPath
}
