import i18n from '@/i18n/index'

/**
 * Define all existing filters which can be used in the filters component
 * Actually they are 2 type of filters => Options and Switch
 * Each time a new filter should be implemented we'll need to add it here to be handled by the filters coponent
 *
 * {String} filterName => the filter name to register for asked filters
 */
const filtersObject = {
  switchsFilter: [
    {
      filterName: 'isInAdstxt',
      filterLabel: i18n.t('global.filters.in_adstxt'),
      key: 'iab',
      paramName: 'ads_txt_status',
      value: false,
      values: ['FOUND', 'ALL']
    },
    // {
    //   filterName: 'isExempted',
    //   filterLabel: i18n.t('global.filters.is_exempted'),
    //   key: 'is_exempted',
    //   paramName: 'is_exempted',
    //   value: false,
    //   values: [true, false]
    // },
    {
      filterName: 'isInTcf',
      filterLabel: i18n.t('global.filters.in_tcf'),
      key: 'tcf',
      paramName: 'TCF',
      value: false,
      values: ['FOUND', 'ALL']
    },
    {
      filterName: 'onlyOrphans',
      filterLabel: i18n.t('global.filters.only_show_orphans'),
      key: 'orphans',
      paramName: 'onlyOrphans',
      value: false,
      values: [true, '']
    },
    // {
    //   filterName: 'videoType',
    //   filterLabel: i18n.t('Programmatic'),
    //   key: 'videoType',
    //   paramName: 'creative_type',
    //   value: true,
    //   values: ['Programmatic', 'Direct']
    // }
  ],
  optionsFilter: [
    {
      filterName: 'riskLevel',
      placeholder: 'Risk above 60%',
      key: 'Risk above 60%',
      paramName: 'riskLevel',
      filterLabel: i18n.t('Risk level'),
      options: [
        {
          text: 'Risk above 60%',
          value: 0.6
        },
        {
          text: 'All',
          value: 0
        }
      ]
    },
    {
      filterName: 'isActiveInTcf',
      placeholder: 'All',
      key: 'isActiveInTcf',
      paramName: 'isActiveInTcf',
      filterLabel: i18n.t('Is active in TCF'),
      options: [
        {
          text: 'Yes',
          value: 'active'
        },
        {
          text: 'No',
          value: 'nonActive'
        },
        {
          text: 'All',
          value: ''
        }
      ]
    },
    {
      filterName: 'hasDeclaredLegitimateInterests',
      placeholder: 'All',
      key: 'hasDeclaredLegitimateInterests',
      paramName: 'hasDeclaredLegitimateInterests',
      filterLabel: i18n.t('TCF legitimate interest declaration'),
      options: [
        {
          text: 'Declare',
          value: 'yes'
        },
        {
          text: 'Do not declare',
          value: 'no'
        },
        {
          text: 'All',
          value: ''
        }
      ]
    },
    {
      filterName: 'videoType',
      placeholder: 'Programmatic',
      key: 'videoType',
      paramName: 'creative_type',
      filterLabel: i18n.t('Type'),
      options: [
        {
          text: 'Programmatic',
          value: 'Programmatic'
        },
        {
          text: 'Direct',
          value: 'Direct'
        }
      ]
    },
    {
      filterName: 'isInCmp',
      placeholder: i18n.t('global.filters.all'),
      key: 'cmp',
      paramName: 'CMP',
      filterLabel: i18n.t('global.filters.cmp_filter'),
      options: [
        {
          text: i18n.t('global.filters.in'),
          value: 'FOUND'
        },
        {
          text: i18n.t('global.filters.out'),
          value: 'NOT_FOUND'
        },
        {
          text: i18n.t('global.filters.all'),
          value: 'All'
        }
      ]
    },
    {
      filterName: 'isExempted',
      placeholder: i18n.t('global.filters.all'),
      key: 'is_exempted',
      paramName: 'is_exempted',
      filterLabel: i18n.t('global.filters.is_exempted'),
      options: [
        {
          text: i18n.t('global.yes'),
          value: 'true'
        },
        {
          text: i18n.t('global.no'),
          value: 'false'
        },
        {
          text: i18n.t('global.filters.all'),
          value: 'All'
        }
      ]
    },
    {
      filterName: 'isInGatp',
      placeholder: i18n.t('global.filters.all'),
      key: 'gatp',
      paramName: 'inGatp',
      filterLabel: i18n.t('global.filters.in_gatp'),
      options: [
        {
          text: i18n.t('global.filters.in'),
          value: 'FOUND'
        },
        {
          text: i18n.t('global.filters.out'),
          value: 'NOT_FOUND'
        },
        {
          text: i18n.t('global.filters.all'),
          value: 'All'
        }
      ]
    },
    {
      filterName: 'vendorSurveillanceStatus',
      key: 'status',
      placeholder: i18n.t('global.filters.all'),
      paramName: 'status',
      filterLabel: i18n.t('global.filters.status_filter'),
      options: [
        {
          text: i18n.t('global.filters.under_qualification'),
          value: 3
        },
        {
          text: i18n.t('global.filters.watchlist'),
          value: 1
        },
        {
          text: i18n.t('global.filters.whitelist'),
          value: 2
        },
        {
          text: 'All',
          value: 0
        }
      ]
    },
    {
      filterName: 'domainLevel',
      key: 'tier',
      placeholder: i18n.t('global.filters.all_levels'),
      paramName: 'tier',
      filterLabel: i18n.t('global.filters.domains_level'),
      options: [
        {
          text: i18n.t('global.filters.all_levels'),
          value: ''
        },
        {
          text: i18n.t('global.filters.level_1'),
          value: 1
        },
        {
          text: i18n.t('global.filters.level_2'),
          value: 2
        },
        {
          text: i18n.t('global.filters.level_3'),
          value: 3
        },
      ]
    },
    {
      filterName: 'cmpScenario',
      key: 'cmpScenarioOutcomes',
      placeholder: i18n.t('All scenarios'),
      paramName: 'cmpScenarioOutcomes',
      filterLabel: i18n.t('CMP scenario'),
      options: [
        {
          text: i18n.t('global.scenarios.without_choice'),
          value: 'NO_ACTION'
        },
        {
          text: i18n.t('global.scenarios.with_choice'),
          value: 'ACCEPT'
        },
        {
          text: i18n.t('global.scenarios.refusal_choice'),
          value: 'REJECT'
        },
        {
          text: i18n.t('All scenarios'),
          value: ''
        }
      ]
    }
  ]
}

export default filtersObject
