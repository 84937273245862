var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-component"
  }, [_vm.container ? _c('Card', {
    attrs: {
      "bordered": false,
      "dis-hover": true
    }
  }, [_vm.showTitle ? _c('p', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.tableHeader) + " ")]) : _vm._e(), _vm.count ? _c('p', [_c('strong', [_vm._v(_vm._s(_vm.$t('global.count')) + ": " + _vm._s(_vm.paging.count))])]) : _vm._e(), _c('br'), _vm.isVendorListFilters ? _c('VendorListFilters') : _vm._e(), _vm.isCookiePolicyFilters ? _c('CookiePolicyFilters') : _vm._e(), _c('Filters', {
    attrs: {
      "allow-download": _vm.allowDownload,
      "show-columns-selector": _vm.showColumnsSelector,
      "options": _vm.whichFiltersOptions,
      "filters": _vm.searchFilters,
      "search-placeholder": _vm.searchPlaceholder
    },
    on: {
      "dl-btn": _vm.exportTableCsv,
      "update-filters": _vm.handleFiltersChange
    }
  }), _vm.showColumnsSelector ? _c('ColumnsFilter', {
    attrs: {
      "column-list": _vm.columns,
      "removed-columns": _vm.removedCols
    },
    on: {
      "checkCol": _vm.handleCheckCol
    }
  }) : _vm._e(), _c('Table', {
    attrs: {
      "border": _vm.border,
      "columns": _vm.updatableCols,
      "span-methos": _vm.spanMethod,
      "loading": _vm.isTableLoadingProp || _vm.tableLoading,
      "stripe": false,
      "data": _vm.data.items
    },
    on: {
      "on-select": function onSelect(selection) {
        return _vm.$emit('on-select', selection);
      },
      "on-sort-change": _vm.handleSortChange
    }
  }, [_c('template', {
    slot: "loading"
  }, [_c('Spin', {
    attrs: {
      "fix": ""
    }
  }, [_c('Icon', {
    staticClass: "demo-spin-icon-load",
    attrs: {
      "type": "ios-loading",
      "size": "18"
    }
  }), _c('div', [_vm._v(_vm._s(_vm.$t('global.loading')))])], 1)], 1)], 2), _c('Page', {
    staticStyle: {
      "text-align": "right",
      "padding": "10px 0px 45px 0px"
    },
    attrs: {
      "current": _vm.paging.page,
      "total": _vm.paging.count,
      "page-size": _vm.paging.limit,
      "size": "small"
    },
    on: {
      "on-change": _vm.handlePageChange
    }
  })], 1) : _c('div', [_vm.count ? _c('p', [_c('strong', [_vm._v(_vm._s(_vm.$t('global.count')) + ": " + _vm._s(_vm.paging.count))])]) : _vm._e(), _c('br'), _vm.isVendorListFilters ? _c('VendorListFilters') : _vm._e(), _c('Filters', {
    attrs: {
      "allow-download": _vm.allowDownload,
      "show-columns-selector": _vm.showColumnsSelector,
      "options": _vm.whichFiltersOptions,
      "filters": _vm.searchFilters,
      "search-placeholder": _vm.searchPlaceholder
    },
    on: {
      "dl-btn": _vm.exportTableCsv,
      "update-filters": _vm.handleFiltersChange
    }
  }), _vm.showColumnsSelector ? _c('ColumnsFilter', {
    attrs: {
      "column-list": _vm.columns,
      "removed-columns": _vm.removedCols
    },
    on: {
      "checkCol": _vm.handleCheckCol
    }
  }) : _vm._e(), _c('Table', {
    attrs: {
      "border": _vm.border,
      "stripe": false,
      "columns": _vm.updatableCols,
      "span-methos": _vm.spanMethod,
      "loading": _vm.isTableLoadingProp || _vm.tableLoading,
      "strip": true,
      "data": _vm.data.items
    },
    on: {
      "on-select": function onSelect(selection, row) {
        return _vm.$emit('on-select', {
          selection: selection,
          row: row
        });
      },
      "on-sort-change": _vm.handleSortChange
    }
  }, [_c('template', {
    slot: "loading"
  }, [_c('Spin', {
    attrs: {
      "fix": ""
    }
  }, [_c('Icon', {
    staticClass: "demo-spin-icon-load",
    attrs: {
      "type": "ios-loading",
      "size": "18"
    }
  }), _c('div', [_vm._v("Loading")])], 1)], 1)], 2), _c('Page', {
    staticStyle: {
      "text-align": "right",
      "padding": "10px 0px 45px 0px"
    },
    attrs: {
      "current": _vm.paging.page,
      "total": _vm.paging.count,
      "page-size": _vm.paging.limit,
      "size": "small"
    },
    on: {
      "on-change": _vm.handlePageChange
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }