<template>
  <div>
    <!-- <Button type="primary"
            @click="isColumnFilterOpen = !isColumnFilterOpen"
    >
      Filter displayed columns (only for super-admin / test phase)
    </Button> -->
    <Card v-if="isColumnFilterOpen"
          :bordered="true"
          :style="{width:'250px',position:'absolute','z-index':'3', left:'549px', top: $route.path === '/trackers' || $route.path === '/vendors' ? '150px' : '130px','box-shadow': '0 1px 6px rgb(0 0 0 / 20%)'}"
    >
      <span slot="title">Columns filter</span>
      <span slot="extra"
            style="cursor:pointer"
            @click="$store.state.options.isColumnFilterOpen = false"
      ><i class="fas fa-times" /></span>
      <List>
        <ListItem v-for="(col, k) in columns"
                  :key="k"
        >
          <Checkbox v-model="col.checked"
                    @on-change="check => triggerCheck(check, col.title)"
                    :label="col.title"
          >
            <span style="margin-left:10px">
              {{ col.title }}
            </span>
          </Checkbox>
        </ListItem>
      </List>
    </Card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FilterColumns',
  props: {
    columnList: {
      type: Array,
      default: () => [],
      note: 'list of columns'
    },
    removedColumns: {
      type: Array,
      default: () => [],
      note: 'list of unchecked columns'
    }
  },
  watch: {
    removedColumns: {
      deep: true,
      handler: function (val, old) {
        val.forEach(elm => {
          this.columns.find(col => col.title === elm).checked = false
        })
      }
    }
  },
  mounted () {
    this.columnList.forEach(elm => {
      this.columns.push({
        title: elm.title,
        checked: !elm.checked || true
      })
    })
  },
  data: function () {
    return {
      columns: []
    }
  },
  computed: {
    ...mapGetters({
      isColumnFilterOpen: 'options/isColumnFilterOpen'
    })
  },
  methods: {
    triggerCheck (checked, colTitle) {
      this.$emit('checkCol', {checked: checked, colTitle: colTitle})
    }
  }
}
</script>
<style lang="scss" scoped>

</style>