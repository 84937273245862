<template>
  <Row :gutter="24"
       style="margin: 20px 0; align-items:center"
       class="cookie-list-filters"
  >
    <Col>
      <Input type="text"
             style="width:300px;"
             suffix="ios-search"
             placeholder="Tracker or vendor name"
             v-model="filters.inputName"
             @input="debounceInput"
      />
    </Col>
    <!-- <Col style="margin-left:auto">
      <Poptip placement="left"
              ref="search-vendor-poptip"
              :width="520"
      >
        <Button type="primary"
                icon="md-add"
        >
          Declare a new vendor
        </Button>
        <DeclareNewVendorPoptip slot="content"
                                ref="declareNewVendorRef"
        />
      </Poptip>
    </Col> -->
    <Col span="24"
         style="margin-top:12px"
    >
      <div style="display:flex; align-items:center">
        <Poptip
          @on-popper-show="triggerPop('type', true)"
          @on-popper-hide="triggerPop('type', false)"
          placement="bottom"
        >
          <span class="filters-btn"
                :class="{ 'filter-active': filtersPoptipStatus.type }"
          >Type
            <span v-if="filters.type.length">{{ filters.type.length }}</span>
            <Icon type="ios-arrow-down"
                  style="margin-left:8px;"
            />
          </span>
          <p slot="content">
            <CheckboxGroup v-model="filters.type">
              <template v-for="(val, key) of possibleTypes">
                <Checkbox style="margin:5px 0"
                          :key="key"
                          :label="val"
                /> <br :key="key + 'br'">
              </template>
            </CheckboxGroup>
          </p>
        </Poptip>
        <Poptip
          @on-popper-show="triggerPop('partyFrom', true)"
          @on-popper-hide="triggerPop('partyFrom', false)"
          placement="bottom"
        >
          <span class="filters-btn"
                :class="{ 'filter-active': filtersPoptipStatus.partyFrom }"
          >1st/3rd party
            <span v-if="filters.partyFrom.length">{{ filters.partyFrom.length }}</span>
            <Icon type="ios-arrow-down"
                  style="margin-left:8px;"
            />
          </span>
          <p slot="content">
            <CheckboxGroup v-model="filters.partyFrom">
              <Checkbox style="margin:5px 0"
                        label="1st party"
              /> <br>
              <Checkbox style="margin:5px 0"
                        label="3rd party"
              />
            </CheckboxGroup>
          </p>
        </Poptip>
        <Poptip
          @on-popper-show="triggerPop('lifetime', true)"
          @on-popper-hide="triggerPop('lifetime', false)"
          placement="bottom"
        >
          <span class="filters-btn"
                :class="{ 'filter-active': filtersPoptipStatus.lifetime }"
          >Lifetime
            <span v-if="filters.lifetime.length">{{ filters.lifetime.length }}</span>
            <Icon type="ios-arrow-down"
                  style="margin-left:8px;"
            />
          </span>
          <p slot="content">
            <CheckboxGroup v-model="filters.lifetime">
              <Checkbox style="margin:5px 0"
                        label="Less or equal 13 months"
              /> <br>
              <Checkbox style="margin:5px 0"
                        label="Greater thant 13 months"
              />
            </CheckboxGroup>
          </p>
        </Poptip>
        <Poptip
          @on-popper-show="triggerPop('purposes', true)"
          @on-popper-hide="triggerPop('purposes', false)"
          placement="bottom"
        >
          <span class="filters-btn"
                :class="{ 'filter-active': filtersPoptipStatus.purposes }"
          >Purposes
            <span v-if="filters.purposes.length">{{ filters.purposes.length }}</span>
            <Icon type="ios-arrow-down"
                  style="margin-left:8px;"
            />
          </span>
          <p slot="content">
            <CheckboxGroup v-model="filters.purposes">
              <template v-for="(val, key) of purposes">
                <Checkbox style="margin:5px 0"
                          :key="key"
                          :label="val.id"
                >
                  <span style="margin: 0 5px">{{ val.id }} - {{ val.name }}</span>
                </Checkbox> <br :key="key + 'br'">
              </template>
            </CheckboxGroup>
          </p>
        </Poptip>
        <!-- <Poptip 
          @on-popper-show="triggerPop('inTCF', true)"
          @on-popper-hide="triggerPop('inTCF', false)"
          placement="bottom"
        >
          <span class="filters-btn"
                :class="{ 'filter-active': filtersPoptipStatus.inTCF }"
          >Is dropped by TCF vendor
            <span v-if="filters.inTCF.length">{{ filters.inTCF.length }}</span>
            <Icon type="ios-arrow-down"
                  style="margin-left:8px;"
            />
          </span>
          <p slot="content">
            <CheckboxGroup v-model="filters.inTCF">
              <Checkbox style="margin:5px 0"
                        label="Yes"
              /> <br>
              <Checkbox style="margin:5px 0"
                        label="No"
              />
            </CheckboxGroup>
          </p>
        </Poptip> -->
        <!-- <Poptip 
          @on-popper-show="triggerPop('isDetected', true)"
          @on-popper-hide="triggerPop('isDetected', false)"
          placement="bottom"
        >
          <span class="filters-btn"
                :class="{ 'filter-active': filtersPoptipStatus.isDetected }"
          >Is detected
            <span v-if="filters.isDetected.length">{{ filters.isDetected.length }}</span>
            <Icon type="ios-arrow-down"
                  style="margin-left:8px;"
            />
          </span>
          <p slot="content">
            <CheckboxGroup v-model="filters.isDetected">
              <Checkbox style="margin:5px 0"
                        label="Yes"
              /> <br>
              <Checkbox style="margin:5px 0"
                        label="No"
              />
            </CheckboxGroup>
          </p>
        </Poptip> -->
        <span style="cursor:pointer;"
              @click="resetFilters"
        ><strong>Clear all</strong></span>
      </div>
      <!-- </Poptip> -->
    </Col>
  </Row>
</template>
<script>
import { eventBus } from '@/main'
import { mapGetters } from 'vuex'
import * as _ from 'lodash'
export default {
  name: 'CookiePolicyFilters',
  data: function () {
    return {
      visible: false,
      bus: eventBus,
      possibleTypes: [
        'Cookie', 'Pixel', 'Web_storage', 'Indexed_db'
      ],
      filtersPoptipStatus: {
        type: false,
        inTCF: false,
        partyFrom: false,
        lifetime: false,
        purposes: false,
        isDetected: false
      },
      filters: {
        inputName: '',
        type: [],
        partyFrom: [],
        lifetime: [],
        purposes: [],
        inTCF: [],
        isDetected: []
      },
      categories: null
    }
  },
  watch: {
    filters: {
      deep:true,
      handler: function () {
        this.bus.$emit('trigger-cookie-policy-filter', this.filters)
      }
    }
  },
  computed: {
    ...mapGetters({
      userHasRole: 'user/hasRole',
    }),
    purposes () {
      const amount = 11
      const purposes = []
      for (let k = 1; k<11; k++) {
        const i18nKey = 'global.tcfv2_f_and_p.purposes.name_p_' + k
        purposes.push({
          id: k,
          name: this.$t(i18nKey)
        })
      }
      return purposes
    }
  },
  methods: {
    triggerPop (filter, val) {
      this.filtersPoptipStatus[filter] = val
    },
    resetFilters () {
      this.filtersPoptipStatus = {
        type: false,
        partyFrom: false,
        lifetime: false,
        purposes: false,
        inTCF: false,
        isDetected: false
      },
      this.filters=  {
        inputName: '',
        type: [],
        partyFrom: [],
        lifetime: [],
        purposes: [],
        inTCF: [],
        isDetected: []
      },
      this.bus.$emit('trigger-cookie-policy-filter', false)
    },
    validFilters () {
      this.bus.$emit('trigger-cookie-policy-filter', this.filters)
    },
    debounceInput: _.debounce(function (e) {
      this.filters.inputName = e.trim() || ''
      this.bus.$emit('trigger-cookie-policy-filter', this.filters)
    }, 500),
  }
}
</script>
<style lang="scss">
.cookie-list-filters {
  .ivu-poptip-body-content {
  overflow: hidden!important
}
.filter-active {
  background: #1CA08D!important;
  color: #fff!important;
  border: 1px solid #1CA08D!important;
  span {
    display: block;
    margin-left:8px;
    width: 19px!important;
    border-radius: 50%;
    line-height: 19px;
    color: inherit;
    background: #fff;
    text-align: center;
  }
  &:hover {
    background: #ebebeb
    span {
      color: #fff;
      background: #1CA08D;
    }
  }
}
.filters-btn {
  box-sizing: border-box;
  padding: 7px;
  cursor:pointer;
  margin-right:12px;
  border: 1px solid #ebebeb;
  display:flex;
  align-items: center;
  span {
    margin-left: 10px;
    display: block;
    color: #fff;
    background: #1CA08D;
    text-align: center;
    width: 19px;
    line-height: 19px;
    border-radius: 50%;
    text-align: center;
    padding: 0;
  }
  &:hover {
    background: #ebebeb
    span {
      border: 1px solid #fff;
      color: inherit;
      background: inherit;
    }
  }
}
}

</style>
