<template>
  <div class="table-component">
    <Card v-if="container"
          :bordered="false"
          :dis-hover="true"
    >
      <!-- <div v-if="name"> -->
      <p slot="title"
         v-if="showTitle"
      >
        {{ tableHeader }}
      </p>
      <!-- </div> -->
      <p v-if="count">
        <strong>{{ $t('global.count') }}: {{ paging.count }}</strong>
      </p>
      <br>
      <VendorListFilters v-if="isVendorListFilters" />
      <CookiePolicyFilters v-if="isCookiePolicyFilters" />
      <Filters
        @dl-btn="exportTableCsv"
        :allow-download="allowDownload"
        :show-columns-selector="showColumnsSelector"
        :options="whichFiltersOptions"
        :filters="searchFilters"
        :search-placeholder="searchPlaceholder"
        @update-filters="handleFiltersChange"
      />
      <ColumnsFilter v-if="showColumnsSelector"
                     :column-list="columns"
                     :removed-columns="removedCols"
                     @checkCol="handleCheckCol"
      />
      <Table
        :border="border"
        @on-select="(selection) => $emit('on-select',selection)"
        :columns="updatableCols"
        :span-methos="spanMethod"
        :loading="isTableLoadingProp || tableLoading"
        :stripe="false"
        :data="data.items"
        @on-sort-change="handleSortChange"
      >
        <template slot="loading">
          <Spin fix>
            <Icon
              type="ios-loading"
              size="18"
              class="demo-spin-icon-load"
            />
            <div>{{ $t('global.loading') }}</div>
          </Spin>
        </template>
      </Table>
      <Page
        :current="paging.page"
        style="text-align: right; padding: 10px 0px 45px 0px"
        :total="paging.count"
        :page-size="paging.limit"
        @on-change="handlePageChange"
        size="small"
      />
    </Card>
    <div v-else>
      <p v-if="count">
        <strong>{{ $t('global.count') }}: {{ paging.count }}</strong>
      </p>
      <br>
      <VendorListFilters v-if="isVendorListFilters" />
      <Filters
        @dl-btn="exportTableCsv"
        :allow-download="allowDownload"
        :show-columns-selector="showColumnsSelector"
        :options="whichFiltersOptions"
        :filters="searchFilters"
        :search-placeholder="searchPlaceholder"
        @update-filters="handleFiltersChange"
      />
      <ColumnsFilter v-if="showColumnsSelector"
                     :column-list="columns"
                     :removed-columns="removedCols"
                     @checkCol="handleCheckCol"
      />
      <Table
        :border="border"
        @on-select="(selection, row) => $emit('on-select',{selection, row})"
        :stripe="false"
        :columns="updatableCols"
        :span-methos="spanMethod"
        :loading="isTableLoadingProp || tableLoading"
        :strip="true"
        :data="data.items"
        @on-sort-change="handleSortChange"
      >
        <template slot="loading">
          <Spin fix>
            <Icon
              type="ios-loading"
              size="18"
              class="demo-spin-icon-load"
            />
            <div>Loading</div>
          </Spin>
        </template>
      </Table>
      <Page
        :current="paging.page"
        style="text-align: right; padding: 10px 0px 45px 0px"
        :total="paging.count"
        :page-size="paging.limit"
        @on-change="handlePageChange"
        size="small"
      />
    </div>
  </div>
</template>

<script>
import Filters from './Filters'
import ColumnsFilter from './ColumnsFilter'
import FileDownload from 'js-file-download'
import { mapGetters } from 'vuex'
import { eventBus } from '@/main'
import VendorListFilters from '@/views/Vendors/VendorList/vendorListFilters.vue'
import CookiePolicyFilters from '@/views/Cookies/CookiePolicy/CookieTableFilters.vue'
export default {
  name: 'FilterTable',
  components: {
    Filters,
    CookiePolicyFilters,
    VendorListFilters,
    ColumnsFilter
  },
  props: {
    isTableLoading: {
      type: Boolean,
      note: 'IF the table is loading',
      default: false
    },
    allowDownload: {
      type: Boolean,
      note: 'Allow table to downlaod data',
      default: true
    },
    showTitle: {
      type: Boolean,
      note: 'Show the card title',
      default: true
    },
    showColumnsSelector: {
      type: Boolean,
      note: 'if the columns filters should be hide',
      default: true
    },
    spanMethod: {
      type: Function,
      note: 'handleSpan iview methods',
      default: () => {}
    },
    border: {
      type: Boolean,
      note: 'table border',
      default: false
    },
    namespace: {
      type: String,
      note: 'Data name',
      default: ''
    },
    count: {
      type: Boolean,
      note: 'display count or no',
      default: false
    },
    name: {
      type: String,
      note: 'Name of the table that will be displayed above the table',
      default: 'Results'
    },
    exportCsv: {
      type: [Function, Boolean],
      default: false,
      note: 'Vuex action to call csv export'
    },
    container: {
      type: Boolean,
      default: false
    },
    fetchItems: {
      type: Function,
      required: true,
      note: 'Function to fetch data, takes paging and filters as parameters and called every time user change page or filters' +
            'Must return an object with this shape {data: Array, count: Number}'
    },
    fetchItemsParams: {
      type: Object,
      require: false,
      default: () => {},
      note: 'Optional params to add into the request body.'
    },
    columnState: {
      type: Array,
      default: () => [],
      note: 'list of column and if they should be active'
    },
    columns: {
      type: Array,
      default: () => [],
      note: 'Columns keys, names and their render methods'
    },
    idColumn: {
      type: String,
      default: () => '',
      note: 'Name of the default column that will be used to sort entries in normal order.'
    },
    limit: {
      type: Number,
      default: () => 10,
      note: 'Number of items to display per page.'
    },
    defaultPaging: {
      type: Object,
      default: () => {},
      note: 'default paging'
    },
    searchFilters: {
      type: Array,
      default: () => [],
      note: 'All the filters applied to list fetching'
    },
    trigger: {
      type: Boolean,
      default: false,
      note: 'wacthed to refresh data'
    },
    paginationRef: {
      type: [String, Boolean],
      note: 'The name of the custom pagination responsability',
      default: false
    },
    isVendorListFilters: {
      type: Boolean,
      note: 'If the table should display the vendorList filter pannel',
      default: false
    },
    isCookiePolicyFilters: {
      type: Boolean,
      note: 'If the table should display the cookie policy filter pannel',
      default: false
    }
  },
  data () {
    return {
      data: {
        items: [],
        count: 0
      },
      updatableCols: [],
      removedCols: [],
      // string list of namespace section which can use help Poptip
      // quick win for category description but it should be reworked to be generic
      // TODO: above
      helpSection: 'vendor,cookie',
      tableLoading: true,
      isTableLoadingProp: this.isTableLoading,
      filters: {},
      paging: {
        count: 1,
        limit: this.limit,
        page: 1,
        sort: '',
        direction: ''
      },
      defaultSortKey: '',
      closeTriger: true
    }
  },
  methods: {
    handleCheckCol (payload) {
      if (payload.checked === false) {
        this.removedCols.push(payload.colTitle)
      } else {
        this.removedCols = this.removedCols.filter(elm => elm !== payload.colTitle)
      }
      this.updatableCols = this.columns.filter(col => {
        if (this.removedCols.includes(col.title)) return false
        return col
      })
      // const mkey = JSON.parse(JSON.stringify(this.fetchItemsParams || {}))
      // delete mkey.period
      // delete mkey.properties
      // const key = this.hash(JSON.stringify(mkey))
      // localStorage.setItem(key, JSON.stringify(this.removedCols))
    },
    exportTableCsv () {
      const params = {
        ...this.filters,
        ...this.fetchItemsParams
      }
      return this.exportCsv(params)
        .then(r => {
          FileDownload(r.data, `List_of_${this.namespace}_${this.scopeSelectedDaterange[0] + '-to-' + this.scopeSelectedDaterange[1]}.csv`)
        }).catch(err => {
          this.$Message.error('Server error. Please try in a few.')
          console.error(err)
        })
    },
    handleFiltersChange (filters) {
      this.filters = filters
      this.paging = {
        ...this.paging,
        page: 1
      }
      this.fetchData()
    },

    handlePageChange (nextPage) {
      this.paging = {...this.paging, page: nextPage}
      const key = 'paginate-' + this.paginationRef
      if (this.paginationRef) {
        console.debug(key, this.paging, {...this.paging, page: nextPage})
        eventBus.$emit(key, {...this.paging, page: nextPage})
      }
      this.fetchData()
    },

    handleSortChange (sortKey) {
      console.debug('handleSortChange', sortKey )
      this.paging.sort = sortKey.column.sortKey
      this.paging.direction = sortKey.order
      this.fetchData(true)
    },

    fetchData (sorted = false) {
      const pagingBase = {
        ...this.paging,
        direction: this.paging.direction || 'normal',
        sort: this.paging.sort || this.defaultSortKey
      }
      const paging = sorted ? pagingBase : Object.assign(pagingBase, this.defaultPaging)

      const params = Object.assign({...this.fetchItemsParams}, {
        ...paging,
        ...this.filters
      })
      return this.fetchItems({params, uid: this._uid}).then(res => {
        if (res.success) {
          console.debug(res)
          const {count} = res.pagination
          console.debug(count)
          this.$emit('on-items-fetched', res)
          this.data = {
            items: res.data,
            count: count
          }

          this.paging.count = count
          this.paging.page = res.pagination.current_page
          this.tableLoading = false
        } else {
          this.$Message.error(res.message)
        }
      }).catch(err => {
        this.$Message.error(err.message)
      })
    }
  },
  computed: {
    whichFiltersOptions () {
      const { columns } = this
      const isCategory = columns.find(elm => elm.title === 'Category')
      return isCategory ? [{label: 'Show category description on hover', mutation: 'vendor/DISPLAY_CATEGORY_HOVER'}] : []
    },
    ...mapGetters({
      scopeSelectedDaterange: 'scope/selectedDaterange',
      hasRole: 'user/hasRole',
      hash: 'hash'
    }),
    tableTitle () {
      const {name} = this

      return name.charAt(0).toUpperCase() + name.substring(1).toLowerCase()
    },
    tableHeader () {
      return `${this.tableTitle} (${this.data.count})`
    },
    searchPlaceholder () {
      if (this.name) {
        return `${this.tableTitle} name...`
      }
      return 'Filter by name...'
    }
  },
  mounted () {
    if (this.idColumn) {
      this.defaultSortKey = this.columns.find(column => column.title === this.idColumn).sortKey
    }
    this.updatableCols = this.columns
    this.columns.forEach(col => col.checked = true)
    const mkey = JSON.parse(JSON.stringify(this.fetchItemsParams || {}))
    delete mkey.period
    delete mkey.properties
    const key = this.hash(JSON.stringify(mkey))
    const removedColumnsConf = localStorage.getItem(key) || false
    if (removedColumnsConf) {
      this.removedCols = JSON.parse(removedColumnsConf)
      this.updatableCols = this.columns.filter(col => {
        if (this.removedCols.includes(col.title)) {
          return false
        }
        return col
      })
    }
    this.fetchData()
  },
  created () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      let namespace = this.namespace
      if (!this.namespace) return false
      // Avoid Vendor comportement based on namespace for specific vendors call (eg: vendorsDomains)
      if (this.namespace.includes('vendor')) namespace = 'vendor'
      // Avoid Notification comportement based on namespace for specific vendors call (eg: notificationType)
      if (this.namespace.includes('notification')) namespace = 'notifications'
      // Avoid Vendor comportement based on namespace for specific vendors call (eg: vendorsDomains)
      if (this.namespace.includes('cookie')) namespace = 'cookie'
      const idCall = this._uid
      const mutationName = namespace + '/GET_' + namespace.toUpperCase() + '_PENDING'

      this.$store.subscribe((mutation, state) => {
        if (mutation.type === mutationName && mutation.payload.id === idCall) {
          this.tableLoading = mutation.payload.state
        }
      })
    })
  },
  beforeDestroy () {
    this.unsubscribe()
  },
  watch: {
    fetchItemsParams: {
      deep: true,
      handler: function (newVal, oldVal) {
        (JSON.stringify(newVal) !== JSON.stringify(oldVal)) && this.fetchData()
      }
    },
    isTableLoading: {
      deep: true,
      immediate: true,
      handler: function(val) {
        this.isTableLoadingProp = val
      }
    },
    trigger: {
      handler: function (neb, old) {
        if (old !== neb) {
          this.fetchData()
          this.tableLoading = true
          this.tableLoading = false
        }
      }
    }
  }
}
</script>
<style lang="scss">
.ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-nav-container {
    height: 50px;
}
.table-component {
  min-height: 500px;
  .ivu-table-wrapper {
    overflow: inherit!important;
  }
}

</style>
