var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Row', {
    staticClass: "vendor-list-filters",
    staticStyle: {
      "margin": "20px 0",
      "align-items": "center"
    },
    attrs: {
      "gutter": 24
    }
  }, [_c('Col', [_c('Input', {
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "type": "text",
      "suffix": "ios-search",
      "placeholder": "Vendor name"
    },
    on: {
      "input": _vm.debounceInput
    },
    model: {
      value: _vm.filters.inputName,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "inputName", $$v);
      },
      expression: "filters.inputName"
    }
  })], 1), _c('Col', {
    staticStyle: {
      "margin-left": "auto"
    }
  }, [_c('Poptip', {
    ref: "search-vendor-poptip",
    attrs: {
      "placement": "left",
      "width": 520
    }
  }, [_c('Button', {
    attrs: {
      "type": "primary",
      "icon": "md-add"
    }
  }, [_vm._v(" Declare a new vendor ")]), _c('DeclareNewVendorPoptip', {
    ref: "declareNewVendorRef",
    attrs: {
      "slot": "content"
    },
    slot: "content"
  })], 1)], 1), _c('Col', {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('Poptip', {
    attrs: {
      "placement": "bottom"
    },
    on: {
      "on-popper-show": function onPopperShow($event) {
        return _vm.triggerPop('legalBasis', true);
      },
      "on-popper-hide": function onPopperHide($event) {
        return _vm.triggerPop('legalBasis', false);
      }
    }
  }, [_c('span', {
    staticClass: "filters-btn",
    class: {
      'filter-active': _vm.filtersPoptipStatus.legalBasis
    }
  }, [_vm._v("Legal basis "), _vm.filters.legalBasis.length ? _c('span', [_vm._v(_vm._s(_vm.filters.legalBasis.length))]) : _vm._e(), _c('Icon', {
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      "type": "ios-arrow-down"
    }
  })], 1), _c('p', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('CheckboxGroup', {
    model: {
      value: _vm.filters.legalBasis,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "legalBasis", $$v);
      },
      expression: "filters.legalBasis"
    }
  }, [_c('br'), _c('Checkbox', {
    staticStyle: {
      "margin": "5px 0"
    },
    attrs: {
      "label": "Only Consent"
    }
  }), _c('br'), _c('Checkbox', {
    staticStyle: {
      "margin": "5px 0"
    },
    attrs: {
      "label": "Only Legitimate Interest"
    }
  })], 1), _c('br')], 1)]), _c('Poptip', {
    attrs: {
      "placement": "bottom"
    },
    on: {
      "on-popper-show": function onPopperShow($event) {
        return _vm.triggerPop('tcf_f_p', true);
      },
      "on-popper-hide": function onPopperHide($event) {
        return _vm.triggerPop('tcf_f_p', false);
      }
    }
  }, [_c('span', {
    staticClass: "filters-btn",
    class: {
      'filter-active': _vm.filtersPoptipStatus.tcf_f_p
    }
  }, [_vm._v("Purposes and features "), _vm.filters.tcf_f_p.length ? _c('span', [_vm._v(_vm._s(_vm.filters.tcf_f_p.length))]) : _vm._e(), _c('Icon', {
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      "type": "ios-arrow-down"
    }
  })], 1), _c('p', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('br'), _c('CheckboxGroup', {
    model: {
      value: _vm.filters.tcf_f_p,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "tcf_f_p", $$v);
      },
      expression: "filters.tcf_f_p"
    }
  }, [_c('Checkbox', {
    staticStyle: {
      "margin": "5px 0"
    },
    attrs: {
      "label": "Only purpose 1 declared on consent"
    }
  }), _c('br'), _c('Checkbox', {
    staticStyle: {
      "margin": "5px 0"
    },
    attrs: {
      "label": "Purpose 1 NOT declared"
    }
  })], 1), _c('br')], 1)]), this.userHasRole('super-admin') ? _c('Poptip', {
    attrs: {
      "placement": "bottom"
    },
    on: {
      "on-popper-show": function onPopperShow($event) {
        return _vm.triggerPop('knowVendors', true);
      },
      "on-popper-hide": function onPopperHide($event) {
        return _vm.triggerPop('knowVendors', false);
      }
    }
  }, [_c('span', {
    staticClass: "filters-btn",
    class: {
      'filter-active': _vm.filtersPoptipStatus.knowVendors
    }
  }, [_vm._v("Known vendors "), _vm.filters.knowVendors.length ? _c('span', [_vm._v(_vm._s(_vm.filters.knowVendors.length))]) : _vm._e(), _c('Icon', {
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      "type": "ios-arrow-down"
    }
  })], 1), _c('p', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('br'), _c('CheckboxGroup', {
    model: {
      value: _vm.filters.knowVendors,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "knowVendors", $$v);
      },
      expression: "filters.knowVendors"
    }
  }, [_c('Checkbox', {
    staticStyle: {
      "margin-bottom": "15px"
    },
    attrs: {
      "label": "Only known vendors"
    }
  }, [_vm._v(" Only unknown vendors ")])], 1)], 1)]) : _vm._e(), _c('Poptip', {
    attrs: {
      "placement": "bottom"
    },
    on: {
      "on-popper-show": function onPopperShow($event) {
        return _vm.triggerPop('vl', true);
      },
      "on-popper-hide": function onPopperHide($event) {
        return _vm.triggerPop('vl', false);
      }
    }
  }, [_c('span', {
    staticClass: "filters-btn",
    class: {
      'filter-active': _vm.filtersPoptipStatus.vl
    }
  }, [_vm._v("In vendor list "), _vm.filters.vl ? _c('span', [_vm._v("1")]) : _vm._e(), _c('Icon', {
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      "type": "ios-arrow-down"
    }
  })], 1), _c('p', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('br'), _c('Checkbox', {
    staticStyle: {
      "margin-bottom": "15px"
    },
    attrs: {
      "label": "Only declared in VL"
    },
    model: {
      value: _vm.filters.vl,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "vl", $$v);
      },
      expression: "filters.vl"
    }
  }, [_vm._v(" Newly declared vendors in vendorlist ")]), _c('br')], 1)]), _c('Poptip', {
    attrs: {
      "placement": "bottom"
    },
    on: {
      "on-popper-show": function onPopperShow($event) {
        return _vm.triggerPop('tcf', true);
      },
      "on-popper-hide": function onPopperHide($event) {
        return _vm.triggerPop('tcf', false);
      }
    }
  }, [_c('span', {
    staticClass: "filters-btn",
    class: {
      'filter-active': _vm.filtersPoptipStatus.tcf
    }
  }, [_vm._v("TCF "), _vm.filters.tcf.length ? _c('span', [_vm._v(_vm._s(_vm.filters.tcf.length))]) : _vm._e(), _c('Icon', {
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      "type": "ios-arrow-down"
    }
  })], 1), _c('p', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('CheckboxGroup', {
    model: {
      value: _vm.filters.tcf,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "tcf", $$v);
      },
      expression: "filters.tcf"
    }
  }, [_c('Checkbox', {
    staticStyle: {
      "margin": "5px 0"
    },
    attrs: {
      "label": "In TCF"
    }
  }), _c('br'), _c('Checkbox', {
    staticStyle: {
      "margin": "5px 0"
    },
    attrs: {
      "label": "Not in TCF"
    }
  })], 1)], 1)]), _c('Poptip', {
    attrs: {
      "placement": "bottom"
    },
    on: {
      "on-popper-show": function onPopperShow($event) {
        return _vm.triggerPop('index', true);
      },
      "on-popper-hide": function onPopperHide($event) {
        return _vm.triggerPop('index', false);
      }
    }
  }, [_c('span', {
    staticClass: "filters-btn",
    class: {
      'filter-active': _vm.filtersPoptipStatus.index
    }
  }, [_vm._v("Trust index "), _vm.filters.index.length ? _c('span', [_vm._v(_vm._s(_vm.filters.index.length))]) : _vm._e(), _c('Icon', {
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      "type": "ios-arrow-down"
    }
  })], 1), _c('p', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('CheckboxGroup', {
    model: {
      value: _vm.filters.index,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "index", $$v);
      },
      expression: "filters.index"
    }
  }, [_c('Checkbox', {
    staticStyle: {
      "margin": "5px 0"
    },
    attrs: {
      "label": "A"
    }
  }, [_c('Tag', {
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      "color": "#81C327",
      "type": "dot"
    }
  }, [_vm._v(" A ")])], 1), _c('br'), _c('Checkbox', {
    staticStyle: {
      "margin": "5px 0"
    },
    attrs: {
      "label": "B"
    }
  }, [_c('Tag', {
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      "color": "#6BAAD8",
      "type": "dot"
    }
  }, [_vm._v(" B ")])], 1), _c('br'), _c('Checkbox', {
    staticStyle: {
      "margin": "5px 0"
    },
    attrs: {
      "label": "C"
    }
  }, [_c('Tag', {
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      "color": "#FFBD41",
      "type": "dot"
    }
  }, [_vm._v(" C ")])], 1), _c('br'), _c('Checkbox', {
    staticStyle: {
      "margin": "5px 0"
    },
    attrs: {
      "label": "D"
    }
  }, [_c('Tag', {
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      "color": "#FF7850",
      "type": "dot"
    }
  }, [_vm._v(" D ")])], 1), _c('br'), _c('Checkbox', {
    staticStyle: {
      "margin": "5px 0"
    },
    attrs: {
      "label": "E"
    }
  }, [_c('Tag', {
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      "color": "#D55D78",
      "type": "dot"
    }
  }, [_vm._v(" E ")])], 1)], 1)], 1)]), _c('Poptip', {
    attrs: {
      "placement": "bottom"
    },
    on: {
      "on-popper-show": function onPopperShow($event) {
        return _vm.triggerPop('categories', true);
      },
      "on-popper-hide": function onPopperHide($event) {
        return _vm.triggerPop('categories', false);
      }
    }
  }, [_c('span', {
    staticClass: "filters-btn",
    class: {
      'filter-active': _vm.filtersPoptipStatus.categories
    }
  }, [_vm._v("Categories "), _vm.filters.categories.length ? _c('span', [_vm._v(_vm._s(_vm.filters.categories.length))]) : _vm._e(), _c('Icon', {
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      "type": "ios-arrow-down"
    }
  })], 1), _c('p', {
    staticStyle: {
      "max-height": "250px",
      "overflow": "auto"
    },
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('CheckboxGroup', {
    model: {
      value: _vm.filters.categories,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "categories", $$v);
      },
      expression: "filters.categories"
    }
  }, [_vm._l(_vm.categories, function (cat, key) {
    return [_c('Checkbox', {
      key: key,
      staticStyle: {
        "margin": "5px 0"
      },
      attrs: {
        "label": cat.name
      }
    }), _c('br', {
      key: key + 'br'
    })];
  })], 2)], 1)]), _c('Poptip', {
    attrs: {
      "placement": "bottom"
    },
    on: {
      "on-popper-show": function onPopperShow($event) {
        return _vm.triggerPop('countryLaws', true);
      },
      "on-popper-hide": function onPopperHide($event) {
        return _vm.triggerPop('countryLaws', false);
      }
    }
  }, [_c('span', {
    staticClass: "filters-btn",
    class: {
      'filter-active': _vm.filtersPoptipStatus.countryLaws
    }
  }, [_vm._v("Country Adequation "), _vm.filters.countryLaws.length ? _c('span', [_vm._v(_vm._s(_vm.filters.countryLaws.length))]) : _vm._e(), _c('Icon', {
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      "type": "ios-arrow-down"
    }
  })], 1), _c('p', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('CheckboxGroup', {
    model: {
      value: _vm.filters.countryLaws,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "countryLaws", $$v);
      },
      expression: "filters.countryLaws"
    }
  }, [_c('Checkbox', {
    staticStyle: {
      "margin": "5px 0"
    },
    attrs: {
      "label": "EU or EEA member country or adequate country"
    }
  }), _c('br'), _c('Checkbox', {
    staticStyle: {
      "margin": "5px 0"
    },
    attrs: {
      "label": "Partially adequate country"
    }
  }), _c('br'), _c('Checkbox', {
    staticStyle: {
      "margin": "5px 0"
    },
    attrs: {
      "label": "Non adequate country"
    }
  }), _c('br'), _c('Checkbox', {
    staticStyle: {
      "margin": "5px 0"
    },
    attrs: {
      "label": "No data protection law(s)"
    }
  })], 1)], 1)]), _c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": _vm.resetFilters
    }
  }, [_c('strong', [_vm._v("Clear all")])])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }