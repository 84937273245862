<template>
  <div>
    <Form @submit.native.prevent>
      <Row type="flex"
           :gutter="12"
      >
        <Col span="8">
          <FormItem v-if="filters.includes('search')">
            <Input
              suffix="ios-search"
              size="large"
              @input="debounceInput"
              :placeholder="searchPlaceholder"
            />
          </FormItem>
        </Col>
        <Col>
          <div wrap>
            <Button v-if="filters.length > 1"
                    type="primary"
                    size="large"
                    @click="$store.state.options.isFilterPanelOpen = !$store.state.options.isFilterPanelOpen; $store.state.options.isColumnFilterOpen = false"
            >
              <i class="fas fa-filter"
                 style="margin-right:5px"
              />
              <span>Filters</span>
            </Button>
  &nbsp;
            <Tooltip v-if="showColumnsSelector"
                     content="Here you can choose which columns you want to display"
                     max-width="200"
                     placement="top"
            >
              <Button type="primary"
                      size="large"
                      @click="openColumnsFilter"
              >
                <i class="fas fa-cog"
                   style="margin-right:5px"
                />
                <span>Columns</span>
              </Button>
            </Tooltip>
          </div>
        </Col>
        <Col style="margin-left:auto"
             v-if="showVendorlistDeclareAction"
        >
          <Button
            style="width: 100%;margin:0;height:inherit!important;"
            size="large"
            icon="md-add"
          >
            Declare a new Vendor
          </Button>
        </Col>
        <Col style="margin-left:auto"
             v-if="allowDownload"
        >
          <Button
            size="small"
            class="table-download"
            style="width: 100%;margin: 5px 0;font-size: 12px; height:inherit!important;"
            type="text"
            @click="$emit('dl-btn')"
          >
            <Icon
              type="md-download"
              :size="22"
            />
          </Button>
        </Col>
      </Row>
    </Form>
    <Card :style="{width:'400px',position:'absolute','z-index':'3', left:'447px', top: $route.path === '/trackers' || $route.path === '/vendors' ? '150px' : '130px','box-shadow': '0 1px 6px rgb(0 0 0 / 20%)'}"
          v-if="isFilterPanelOpen"
    >
      <span slot="title">
        Table filters
      </span>
      <span slot="extra"
            style="cursor:pointer"
            @click="$store.state.options.isFilterPanelOpen = false;"
      ><i class="fas fa-times" /></span>
      <div>
        <Form
          @submit.native.prevent
          v-if="toogleRender"
          style="display:flex; flex-wrap:wrap; flex-direction:column"
        >
          <FormItem v-for="(option, key) of options"
                    :label-width="0"
                    :key="key"
          >
            <Checkbox @on-change="val => toogleCheckbox(val, option)"
                      v-model="showCategoryHelper"
            >
              {{ option.label }}
            </Checkbox>
          </FormItem>


          <FormItem
            v-for="(filter, key) of askedFiltersObjets.switch"
            style="display:flex;margin-right:15px"
            :key="key + 'switch'"
          >
            <span slot="label">{{ filter.filterLabel }}</span>
            <i-switch
              v-model="askedFiltersState[filter.key]"
              @on-change="updateFilters"
            >
              <span slot="open" />
              <span slot="close" />
            </i-switch>
          </FormItem>
          <FormItem
            v-for="(filter, key) of askedFiltersObjets.options"
            :key="key + 'options'"
            style="display:flex;margin-right:15px"
          >
            <span slot="label">{{ filter.filterLabel }}</span>
            <Select
              v-model="askedFiltersState[filter.key]"
              :placeholder="filter.placeholder"
              @on-change="updateFilters"
            >
              <Option
                v-for="item in filter.options"
                :value="item.value"
                :key="item.value"
              >
                {{ item.text }}
              </Option>
            </Select>
          </FormItem>
        </Form>
      </div>
    </Card>
  </div>
</template>
<script>
import filtersObject from './filtersList'
import * as _ from 'lodash'
import {mapGetters, mapMutations} from 'vuex'
export default {
  name: 'FilterTabs',
  props: {
    showVendorlistDeclareAction: {
      type: Boolean,
      note: 'Display the vendorlist button action',
      default: false
    },
    allowDownload: {
      type: Boolean,
      note: 'Allow table to downlaod data',
      default: true
    },
    showColumnsSelector: {
      type: Boolean,
      note: 'if the columns filters should be hide',
      default: false
    },
    filters: {
      type: Array,
      note: 'Asked filters',
      default: () => []
    },
    searchPlaceholder: {
      type: String,
      note: 'the search placeholder',
      default: ''
    },
    options: {
      type: Array,
      note: 'display option for the Table',
      default: () => []
    }
  },
  data () {
    return {
      toogleRender: true,
      filterPanelOpen: false,
      filtersObject: filtersObject,
      askedFiltersObjets: {
        search: false,
        switch: [],
        options: []
      },
      askedFiltersState: {}
    }
  },
  mounted () {
    this.defineAllowedFilters()
    const routeQuery = this.$route.query
    if (routeQuery && routeQuery.params) {
      this.autoApplyFilters(routeQuery.params)
    }
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      /**
       *Reset filters when table changes
       */
      handler: function (n, o) {
        this.defineAllowedFilters()
      }
    }
  },
  computed: {
    ...mapGetters({
      redirects: 'notifications/redirects',
      isFilterPanelOpen: 'options/isFilterPanelOpen',
      cotegoryHelper: 'vendor/showCategoryHelper'
    }),
    showCategoryHelper: {
      set () {
        return true
      },
      get () {
        return this.cotegoryHelper
      }
    },
    /**
     * Remove false value from switch and 0 value from options
     * in order to count only active filters (false a 0 are default value and should not be count as active filter)
     */
    countActivesFilter () {
      let cleanedActivesArray = Object.values(JSON.parse(JSON.stringify(this.askedFiltersState))).filter(val => val !== false && val !== 0)
      return cleanedActivesArray.length
    }
  },
  methods: {
    toogleCheckbox (value, option) {
      this.$store.commit(option.mutation, value)
    },
    openColumnsFilter () {
      this.$store.state.options.isColumnFilterOpen = !this.$store.state.options.isColumnFilterOpen
      this.$store.state.options.isFilterPanelOpen = false
    },

    /**
     * Methods called if there are filters parameters in the URL
     * automatically apply defined filters
     */
    autoApplyFilters (filterCode) {
      const mappedFilter = this.redirects.find(elm => elm.name === filterCode)
      mappedFilter.filters.forEach(filter => {
        this.askedFiltersState = Object.assign(this.askedFiltersState, filter)
      })
      return this.updateFilters()
    },
    /**
     * Map the local definition of available filters (./filterList.js)
     * with the sent (props) array of asked filter
     *
     * - Define first if it ask for a searchBar
     *
     * - Define if it ask for switch filters
     *
     * - Define if it ask for options filters
     *
     * - ...(for new kind of filters)
     *
     * add to the data component the asked filters objects in order to render it
     */
    defineAllowedFilters () {
      let askedFilters = this.filters
      const { filtersObject } = this
      const obj = {
        search: false,
        switch: [],
        options: []
      }
      if (askedFilters.includes('search')) {
        obj.search = true
        askedFilters = askedFilters.filter(elm => elm !== 'search')
      }
      askedFilters.forEach(asked => {
        filtersObject.switchsFilter.forEach(sFilter => {
          if (sFilter.filterName === asked) obj.switch.push(sFilter)
        })
        filtersObject.optionsFilter.forEach(oFilter => {
          if (oFilter.filterName === asked) obj.options.push(oFilter)
        })
      })
      this.askedFiltersObjets = Object.assign(this.askedFiltersObjets, obj)
    },

    /**
     * Create a new Object where all Boolean value are replaced by understandble API parameters
     *
     * Here the switch component only allow to bind Boolean value
     */
    rewriteApiAcceptedValue (activeFilters) {
      let f = {}
      const {askedFiltersObjets} = this
      for (const [k, val] of Object.entries(activeFilters)) {
        const allFilters = [...askedFiltersObjets.options, ...askedFiltersObjets.switch]
        const currentFilter = allFilters.find(filter => filter.key === k)

        if (typeof val === 'boolean' && currentFilter.values) {
          f[k] = val ? currentFilter.values[0] : currentFilter[1]
        } else {
          f[k] = val
        }
        if (val === '') {
          delete f[k]
        }
      }
      return f
    },

    debounceInput: _.debounce(function (e) {
      this.askedFiltersState.q = e.trim() || ''

      this.updateFilters()
    }, 500),

    /**
     *
     */
    prepareParamsFor (filters) {
      const draft = {}
      const contatFilers = [...this.filtersObject.switchsFilter, ...this.filtersObject.optionsFilter]
      Object.entries(filters).forEach(elm => {
        const k = elm[0]
        const v = elm[1]
        const selectedFilter = contatFilers.find(elm => elm.key === k)
        if (selectedFilter) {
          draft[selectedFilter.paramName] = v
        } else {
          draft[k] = v
        }
      })
      return draft
    },
    /**
     * Send an object with the list of parameter the component should apply
     * eg : {iab: 'ALL', tcf: 'FOUND'}
     */
    updateFilters () {
      const filtersDraft = this.rewriteApiAcceptedValue(this.askedFiltersState)
      const params = this.prepareParamsFor(filtersDraft)
      this.$emit('update-filters', params)

      // Strange fix => if a Select component have state pass trough the url parameter,
      // the selected options is no reactive
      this.toogleRender = false
      this.toogleRender = true
    }
  }
}
</script>
<style lang="scss" scope>

</style>
